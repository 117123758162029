<template>
	<section class="container">
		<Card
			background="block"
			:loading="loading"
		>
			<template #header>
				<HeaderCurso
					:title-curso="curso.title"
					:step="step"
				/>
			</template>
			<div class="ebradi-card-body">
				<HeaderInfo
					:image="header_info.image"
					:title="header_info.title"
					:sub-title="header_info.sub_title"
				>
					<template
						v-if="step === 1 && false"
						#info="{ toggleModalLogin }"
					>
						<h5>Já é aluno? Faça <a @click="() => toggleModalLogin()">login</a></h5>
					</template>
				</HeaderInfo>
				<div class="box-resumo">
					<FormInscricao
						v-if="step === 1"
						ref="form_inscricao"
						:inscricao="inscricao"
						:handle-back="back"
						:handle-submit="inscrever"
					/>
					<FormPagamento
						v-if="step === 2"
						ref="form_pagamento"
						:curso="curso"
						:dias-vencimento="dias_vencimento"
						:gratuito="gratuito"
						:curso-livre="cursoLivre"
						:contrato="contrato"
						:loading-contrato="loadingContrato"
						:opcao-pagamento="opcao_pagamento"
						:infos="infos"
						:cupom-applied="cupom"
						:error-cupom="errorCupom"
						:success-cupom="successCupom"
						:handle-cupom="searchCupom"
						:handle-parcela="searchParcela"
						:handle-back="backStep"
						:handle-submit="matricular"
					/>
				</div>
			</div>
		</Card>
		<Recaptcha ref="grecaptcha" />
		<LoadingPage v-model="loading" />
	</section>
</template>

<script>
import { event } from 'vue-gtag';

import Card from '@ebradi/componets/Card';
import FormPagamento from '@ebradi/componets/FormPagamento';
import FormInscricao from '@ebradi/componets/FormInscricao';
import HeaderCurso from '@ebradi/componets/HeaderCurso';
import HeaderInfo from '@ebradi/componets/HeaderInfo';
import LoadingPage from '@ebradi/componets/LoadingPage';
import Recaptcha from '@components/Recaptcha';

import { capitalizeIgnoringArticles, phoneFormatter, generateUUID } from '@commons/helpers';

export default {
	name: 'CheckoutPageEBRADI',
	components: {
		Card,
		FormInscricao,
		FormPagamento,
		HeaderCurso,
		HeaderInfo,
		LoadingPage,
		Recaptcha,
	},
	data() {
		return {
			loading: false,
			curso: {
				title: '',
				id: this.$route.params.curs_id,
			},
			inscricao: {},
			form: {
				captcha: '',
			},
			infos: {
				social_name: '',
				name: '',
				cpf: '',
				matricula: '',
			},
			step: 1,
			header_info: {
				title: 'Cadastro',
				sub_title: 'Confirme seus dados para prosseguirmos',
				image: require('@ebradi/assets/img/image-3.svg')
			},
			cand_id: null,
			cand_token: null,
			dias_vencimento: [],
			gratuito: false,
			catalogo: {},
			loadingContrato: false,
			contrato: '',
			opcao_pagamento: {},
			cupom: null,
			errorCupom: '',
			successCupom: '',
		}
	},
	computed: {
		cursoLivre() {
			return this.inscricao.nens_id === '9' || this.$route.params.nens_id === '9';
		}
	},
	mounted() {
		this.loading = true;

		if (!window.grecaptcha) {
			const recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
			document.head.appendChild(recaptchaScript);
			recaptchaScript.onload = () => {
				window.grecaptcha.ready(() => {
					this.authenticate();
				});
			};
		} else {
			this.authenticate();
		}
	},
	methods: {
		async authenticate() {
			this.loading = true;

			const checkoutAssistido = this.$route.meta.assistido;

			try {
				if (checkoutAssistido) {
					this.step = 2;

					await this.carregaCandidato();
					await this.carregaCurso(false);
				} else {
					await this.carregaCurso();
				}

				event('begin_checkout', {
					currency: 'BRL',
					transaction_id: generateUUID(),
					// value: 7.77,
					coupon: null,
					items: [{
						item_id: this.curso.id,
						item_name: this.curso.title,
						coupon: null,
						index: 0,
						// discount: 2.22,
						item_brand: this.$store.state.ie.sigla,
						item_category:  this.curso.moex_sigla,
						item_category2:  this.curso.nens_nome,
						item_category3:  this.curso.curs_duracao,
						item_category4:  this.curso.area,
						// item_variant: 'green',
						// price: 9.99,
						quantity: 1
					}]
				});
			} finally {
				this.loading = false;
			}
		},

		async login() {
			if (this.$store.getters['sessao/isCheckoutVerified']) {
				return;
			}

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch {
				this.toastOpen('Recaptcha inválido!', 'is-danger');

				return;
			}

			return this.$store.dispatch('sessao/authCheckout', this.form.captcha);
		},

		async carregaCurso(showError = true) {
			const payload = {
				curs_id: this.$route.params.curs_id || this.curso.id,
				nens_id: this.$route.params.nens_id || this.inscricao.nens_id,
			};

			try {
				const [ res ] = await this.$store.dispatch('ebradi/getCurso', payload);

				this.curso = {
					title: capitalizeIgnoringArticles(res.curs_nome),
					id: res.curs_id,
					...res,
				}
			} catch (error) {
				if (showError) {
					this.toastOpen('Erro ao recuperar dados do curso', 'is-danger');
				}
			}
		},

		async carregaCandidato() {
			if (!this.$route.query.token) {
				this.toastOpen('Erro ao buscar dados do checkout assistido', 'is-danger');

				return;
			}

			try {
				const res = await this.$store
					.dispatch('ebradi/recuperarCandidato', {
						cand_token: this.$route.query.token
					});

				this.curso.title = capitalizeIgnoringArticles(res.curso);
				this.curso.id = res.curs_id;
				this.cand_token = res.cand_token;
				this.gratuito = this.metodo_pagamento === 'Gratuito';
				this.inscricao = {
					nens_id: res.nens_id,
					dados_pessoais: {
						cpf: res.cand_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
						endereco: res.endereco,
						email: res.cand_email,
						matricula: res.alun_matricula,
						name: res.cand_nome,
						phone: phoneFormatter(res.cand_fone_celular),
						social_name: res.cand_nome_social,
					}
				}
				this.infos = {
					social_name: res.cand_nome_social,
					name: res.cand_nome,
					cpf: res.cand_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
					matricula: res.alun_matricula,
				}
				this.cand_id = res.cand_id;
				if (res.cupom) {
					this.successCupom = 'Cupom válido';
					this.cupom = res.cupom;
				}
				await this.metodosPagamento(res.cand_id, res.cupom);
			} catch (error) {
				this.toastOpen('Erro ao recuperar dados do candidato', 'is-danger');
			}
		},

		async inscrever(payload) {
			this.loading = true;

			await this.login();

			this.inscricao = {
				...payload,
				dados_pessoais: {
					...payload.dados_pessoais,
					endereco: `${payload.endereco.endereco}${payload.endereco.number ? `, N° ${payload.endereco.number},` : ', S/N,'}${payload.endereco.complement ? ` - ${payload.endereco.complement},` : ''} ${payload.endereco.bairro}, ${payload.endereco.cidade}-${payload.endereco.estado}, ${payload.endereco.zipcode}`
				}
			};

			this.$store.dispatch('ebradi/inscrever', payload)
				.then(async (res) => {
					const { insc_id, cand_token } = res;
					this.step = 2;
					this.cand_id = insc_id;
					this.cand_token = cand_token;
					event('add_shipping_info', {
						currency: 'BRL',
						transaction_id: generateUUID(),
						// value: 7.77,
						items: [{
							item_id: this.curso.id,
							item_name: this.curso.title,
							index: 0,
							item_brand: this.$store.state.ie.sigla,
							item_category:  this.curso.moex_sigla,
							item_category2:  this.curso.nens_nome,
							item_category3:  this.curso.curs_duracao,
							item_category4:  this.curso.area,
							// item_variant: 'green',
							// price: 9.99,
							quantity: 1
						}]
					});
					await this.metodosPagamento(insc_id);
				})
				.catch(() => {
					this.toastOpen('Erro ao tentar se inscrever no processo seletivo', 'is-danger');
					this.loading = false;
				});
		},

		async matricular(payload) {
			this.loading = true;

			await this.login();

			const dadosPessoais = this.inscricao.dados_pessoais;

			const formaPagamento = this.gratuito
				? 'Gratuito'
				: this.$refs.form_pagamento.form.metodo_pagamento.label
			;

			const resumo = this.$refs.form_pagamento.resumo;

			const valorFinal = resumo?.valor_final || null;

			const descricaoValor = resumo?.label?.replace(/(com|sem)\s+juros/i, '')?.trim() || null;

			const matricula = this.$store.dispatch(
				'ebradi/matricular',
				{
					...payload,
					cand_id: this.cand_id,
				}
			).then(() => {
				event('purchase', {
					currency: 'BRL',
					transaction_id: generateUUID(),
					value: valorFinal,
					coupon: this.cupom,
					payment_type: formaPagamento,
					items: [{
						item_id: this.curso.id,
						item_name: this.curso.title,
						coupon: this.cupom,
						index: 0,
						// discount: 2.22,
						item_brand: this.$store.state.ie.sigla,
						item_category:  this.curso.moex_sigla,
						item_category2:  this.curso.nens_nome,
						item_category3:  this.curso.curs_duracao,
						item_category4:  this.curso.area,
						// item_variant: 'green',
						// price: 9.99,
						quantity: 1
					}]
				});
			});


			this.loading = false;

			this.$router.push({
				name: 'resumo',
				query: {
					cand_token: this.cand_token,
				},
				params: {
					aguardar: matricula,
					curso_nome: this.curso.title,
					cand_nome: dadosPessoais.name,
					cand_nome_social: dadosPessoais.social_name,
					cand_fone_celular: dadosPessoais.phone,
					cand_email: dadosPessoais.email,
					cand_cpf: dadosPessoais.cpf,
					endereco: dadosPessoais.endereco,
					forma_pagamento: formaPagamento,
					descricao_valor: descricaoValor,
					numero_parcelas: this.$refs.form_pagamento.form.parcelas,
					valor_original: this.catalogo.valor_base,
					valor_final: valorFinal,
				},
			});
		},

		async metodosPagamento(cand_id, cupom = null) {
			const payload = {
				cand_id,
				...(cupom && { cupom }),
			};

			return this.$store.dispatch('ebradi/getPagamentos', payload)
				.then(async (res) => {
					const { data: { curso_gratuito, dias_vencimento, catalogo } } = res;
					this.gratuito = curso_gratuito;
					this.catalogo = catalogo;
					if (dias_vencimento.length) {
						this.dias_vencimento = dias_vencimento;
					}
					this.header_info.sub_title = 'Concluindo esta etapa você terá acesso ao portal';
					if (curso_gratuito) {
						this.header_info.title = 'Contrato e Termos';
						this.header_info.image = require('@ebradi/assets/img/image-5.svg');
						await this.loadContract();
					} else {
						this.header_info.title = 'Pagamento e Contrato';
						this.header_info.image = require('@ebradi/assets/img/image-1.svg');
						const { data: { opcao_pagamento } } = res;
						this.opcao_pagamento = opcao_pagamento;
					}

					this.loading = false;
				})
				.catch(() => {
					this.toastOpen('Erro ao tentar buscar métodos de pagamento', 'is-danger');
					this.loading = false;
				});
		},

		async searchCupom() {
			this.errorCupom = '';
			this.successCupom = '';

			const { cupom, parcelas } = this.$refs.form_pagamento.form;
			const payload = {
				cand_id: this.cand_id,
				parcelas,
				cupom,
			};

			if (['', null, undefined].includes(cupom)) {
				await this.$store.dispatch('ebradi/getPagamentos', {
					...payload,
					cupom: null,
				})
					.then(async (res) => {
						const { data: { opcao_pagamento } } = res;
						this.opcao_pagamento = opcao_pagamento;
					})
					.catch(() => {
						this.toastOpen('Erro ao tentar buscar métodos de pagamento', 'is-danger');
					}).finally(async () => {
						this.loadContract();
					});

				return;
			}

			await this.$store.dispatch('ebradi/getPagamentos', payload)
				.then(async (res) => {
					const { data: { opcao_pagamento } } = res;
					this.successCupom = 'Cupom válido';
					this.opcao_pagamento = opcao_pagamento;
				})
				.catch((res) => {
					this.errorCupom = res.data.message;
					this.$refs.form_pagamento.form.cupom = '';
					this.$store.dispatch('ebradi/getPagamentos', {
						...payload,
						cupom: null,
					})
						.then(async (res) => {
							const { data: { opcao_pagamento } } = res;
							this.opcao_pagamento = opcao_pagamento;
						})
						.catch(() => {
							this.toastOpen('Erro ao tentar buscar métodos de pagamento', 'is-danger');
						});
				}).finally(async () => {
					await this.loadContract();
				});
		},

		async searchParcela() {
			await this.loadContract()
		},

		back() {
			history.back();
		},

		backStep() {
			this.step = 1;
			this.header_info.title = 'Cadastro';
			this.header_info.sub_title = 'Confirme seus dados para prosseguirmos';
			this.header_info.image = require('@ebradi/assets/img/image-3.svg');
		},

		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},

		async loadContract() {
			const payload = {
				cand_token: this.cand_token,
				cupom: this.$refs.form_pagamento.form.cupom,
				oppa_id: this.$refs.form_pagamento.form.metodo_pagamento.id,
				parcelas: this.$refs.form_pagamento.form.parcelas,
			};

			Object.keys(payload)
				.map(index => {
					if (!payload[index]) {
						delete payload[index];
					}
				});

			this.contrato = '';

			this.loadingContrato = true;

			await this.$store.dispatch('ebradi/getContrato', payload)
				.then(({ contrato, contrato_bolsa: contratoBolsa }) => {
					this.contrato = `${contrato}${contratoBolsa ? `<br /><br />${contratoBolsa}` : ''}`;
				})
				.catch(() => {
					this.toastOpen('Erro ao buscar dados do contrato', 'is-danger');
				})
				.finally(() => {
					this.loadingContrato = false;
				});
		},
	}
}
</script>

<style lang="scss" scoped>

.ebradi-card-body {
	padding: 66px 70px 48px;
}

.box-resumo {
	margin-top: 88px;
}

@media screen and (max-width: 900px)  {
	.ebradi-card-body {
		padding: 21px 17px;
	}

	.box-resumo {
		margin-top: 0px;
	}
}
</style>
